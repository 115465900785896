import http from '@/services/http/http'
import catch_http_error from '@/services/http/catch_http_error'
import validation_errors from '@/services/http/validation_errors'
import { APP_TOKEN } from '@/services/constants'

export async function register(user) {
  try {
    const url = '/api/v1/registrations'
    const { captcha_token} = user
    const res = await http.post(url, { user, token: APP_TOKEN, 'g-recaptcha-response': captcha_token })
    return res.data
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}

export async function getPendingAccount(id) {
  try {
    const url = '/api/v1/registrations/' + id
    const res = await http.get(url)
    return res.data
  } catch(e) {
    catch_http_error(e)
  }
}

export async function resendConfirmation(id) {
  try {
    const url = '/api/v1/registrations/resend_confirmation'
    const res = await http.post(url, { id })
    return res.data
  } catch(e) {
    catch_http_error(e)
  }
}

export async function confirmAccount(id, code) {
  try {
    const url = '/api/v1/registrations/' + id
    const res = await http.put(url, { code })
    return res.data
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422)
      throw new Error('Invalid confirmation code')
    else
      catch_http_error(e)
  }
}


export async function resendConfirmationInstructions(email){
  try{
    const url = '/api/v1/confirmations/'
    const res = await http.post(url, {email})
    return res.data
  }catch(e){
    catch_http_error( e.response.data)
  }
}