<template>
 <v-app>
  <div class="resend-confirmation-instructions">
    <v-container>
      <v-row>
        <v-col>
          <div class="circle-logo text-center pt-12">
            <logo-img></logo-img>
          </div>
          <div class="logo-img">
            <div class="text-center">
              <logo class="logo"/>
              <div class="caption primary--text">Manager</div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row align-content="center">
        <v-col cols="12" sm="6" offset-sm="3">
          <div class="wrapper mx-auto">
            <div class="text-center">
              <h1 class="h1">Resend Confirmation Instructions</h1>
              <p>
                We will send the confirmation instructions to your email.
              </p>
            </div>
            <v-form @submit.prevent="send" ref="form" v-model="valid" lazy-validation>
              <v-text-field type="email" label="Type your email" v-model="email" :rules="emailRules" :error-messages="error" outlined >
              </v-text-field>
              <v-btn type="submit" block color="primary" :loading="loading" :disabled="loading || !valid" 
              >
                Send
              </v-btn>
            </v-form>
            <p class="overline mt-6">
              Already have an account?
              <router-link :to="{name: 'Login'}">Sign In</router-link>
              <br>
              Don't have account?
              <router-link :to="{name: 'Register'}">Create Account</router-link>

            </p>
          </div>
        </v-col>

      </v-row>
    </v-container>
  </div>
 </v-app>
</template> 


<script>  
  import Logo from '@/components/Logo'
  import LogoImg from '@/components/LogoImg'
  import {emailRules} from '@/services/validators'
  import {resendConfirmationInstructions} from '@/services/http/registrations'
  export default {
    name: 'ResendConfirmation',
    components: {
      Logo, LogoImg
    },
    data() {
      return {
        loading: false,
        error: null,
        valid: true,
        email: '',
        emailRules
      }
    },
    methods: {
      async send(){
        try{
          if(this.$refs.form.validate()){
            this.loading = true
            await resendConfirmationInstructions(this.email)
            await this.$toast('You will receive an email with instructions for how to confirm your email address in a few minutes.')
            this.$router.push({name: 'Login'})
          }

        }catch(e){
          this.error = e.error.toString()
        }finally {
          this.loading = false
        }
      }
    },
    watch: {
      email(v) {
        if(v) this.error = null
      }
    }
  }

</script>